<template>
    <div>
        <row ref="row" :base="$options.name"></row>
    </div>
</template>

<script>
    import row from '@/components/row.vue'

    export default {
        name: "biLuo",
        components:{
            row
        },
        data() {
            return {
                list:[
                    {
                        title: "使用前检查",
                        text:[
                            '#初始环境必须成功(漠西场景会失败,退出漠西再初始环境,如果失败看菜单初始环境教程)(图1)',
                        ],
                        img:[
                            "com/init.png",
                        ]
                    },
                    {
                        title: "功能说明",
                        text:[
                            "#切换到漠西设置页",
                            "#按图1说明参数:",
                            "1.必填项-切换输出套路:输出套路是指你的角色主套路,用来打怪的,根据快捷栏设置切换快捷键" +
                            "(9小代表小键盘的9按键,其他带小的是同理)",
                            "2.必填项-技能:上面切换套路后,技能对应的快捷键,根据快捷栏设置",
                        ],
                        img:[
                            "com/moxi.png",
                        ]
                    },
                    {
                        title: "功能说明",
                        text:[
                            "#切换到碧落页",
                            "#按图1说明参数:",
                            "1.选填项-可以勾选定时结束,结束后可以接其他任务,不勾选会一直刷",
                        ],
                        img:[
                            "1.png",
                        ]
                    },
                    {
                        title: "游戏说明",
                        text:[
                            "#注意识别区域,防止误使用药品食物(图1)",
                            "#以上设置好,开始任务",
                        ],
                        img:[
                            "com/jinwudao.png",
                        ]
                    },
                ],
            }
        },
        methods:{

        },
        mounted() {
            this.$refs.row.initData(this.list);
        }
    }
</script>

<style scoped>

</style>
